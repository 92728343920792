import { FancyModalProvider } from '@/Components/Modals/Fancy/Context/FancyModalContext';
import { InertiaProvider } from '@/Contexts/InertiaContext';
import LoaderLayout from '@/Layouts/LoaderLayout';

import { persistor, store } from '@/Redux/store';
import '../scss/app.scss';
import './bootstrap';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const appName = document.title || 'Laravel';

const InertiaWrapper = ({ children, page }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <InertiaProvider pageProps={page?.props}>
          <FancyModalProvider>
            <LoaderLayout>{children}</LoaderLayout>
          </FancyModalProvider>
        </InertiaProvider>
      </PersistGate>
    </Provider>
  );
};

createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  resolve: name =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx')
    ),
  setup: ({ el, App, props }) => {
    const root = createRoot(el);

    root.render(
      <InertiaWrapper page={props.initialPage}>
        <App {...props} />
      </InertiaWrapper>
    );
  },
  progress: {
    color: '#4B5563',
  },
}).then();
